@mixin bigCoinruleBut {
  height: 46px;
  font-size: 15px;
  padding: 14px 22px 0;
  line-height: 17px;
}

.coinruleBut {
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  font-weight: 600 !important;
  position: relative;
  z-index: 0;
  display: inline-block;
  background-color: transparent;
  border: none;
  margin: 10px 0;
  text-decoration: none;
  text-align: left;
  outline: none;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 100px;
    transition: width 0.2s ease;
  }

  &_bordered {
    color: #9698ab;

    &::before {
      border: 1px solid #9698ab;
    }
  }

  &:hover::before {
    width: calc(100% + 6px);
    //opacity: 0.8;
  }

  &_disabled {
    opacity: 0.6;
    cursor: default;
    pointer-events: none;

    &:hover::before {
      width: 100%;
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.coinruleBut_disabled {
  opacity: 0.5;
}

.coinruleBut_logout {
  background: transparent !important;
  color: #6774a1 !important;
  text-align: right !important;
}

//Sizes
.coinruleBut_medium {
  height: 40px;
  font-size: 15px;
  padding: 12px 17px 0;
  line-height: 17px;
  font-weight: 400;
}
.coinruleBut_big {
  @include bigCoinruleBut();
}

.coinruleBut__xl {
  @include bigCoinruleBut();
  width: 155px;
}

//Colors
.coinruleBut_crimson {
  color: #fff;

  &::before {
    box-shadow: 0 3px 0 #cc0b47;
    background-color: #ff165e;
  }

  &:hover {
    color: #fff;
  }
}
.coinruleBut_violet {
  color: #fff;

  &::before {
    box-shadow: 0 3px 0 #3642da;
    background-color: #4d5aff;
  }

  &:hover {
    color: #fff;
  }

  &-bordered {
    &::before {
      border: 1px solid #4d5aff;
    }
  }
}
.coinruleBut_white {
  color: black;
  font-weight: 400;

  &::before {
    background-color: white;
    border: 1px solid grey;
  }

  &:hover {
    color: black;
  }
}
.coinruleBut_lightGray {
  color: white;

  &::before {
    box-shadow: 0 3px 0 #616676;
    background-color: #8a8e9b;
    border-color: #8a8e9b;
  }
}

.coinruleBut_gray {
  color: #000;

  &::before {
    box-shadow: 0 3px 0 #9d9d9d;
    background-color: #c4c4c4;
    border-color: #c4c4c4;
  }

  &:hover {
    color: #000;
  }
}

.coinruleBut_deepDarkBlue {
  color: #fff;

  &::before {
    box-shadow: 0 3px 0 #181e31;
    background-color: #252d49;
  }

  &:hover {
    color: #fff;
  }
}

.coinruleBut_green {
  color: #fff;

  &::before {
    box-shadow: 0 3px 0 #51b422;
    background-color: #6edb3a;
  }

  &:hover {
    color: #fff;
  }
}

.coinruleBut_lightGreen {
  color: #fff;

  &::before {
    box-shadow: 0 3px 0 #2e34ae;
    background-color: #88ce3b;
  }

  &:hover {
    color: #fff;
  }
}

//Color fill type
.coinruleBut_outline {
  &::before {
    border-width: 2px;
    border-style: solid;
    background: transparent;
    box-shadow: none;
  }
}

//Icons
@mixin icon {
  padding-right: 80px;
  text-align: left;

  &::after {
    content: '';
    width: 17px;
    height: 17px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    background-position: 50% 50%;
    background-size: 15px;
    background-repeat: no-repeat;
  }
}

.coinruleBut_iconConnect {
  @include icon;

  &::after {
    opacity: 0.42;
    width: 18px;
    height: 18px;
    right: 14px;
    background-size: 18px;
    background-image: url(../assets/images/icons/coinruleBut_iconConnect_after.svg);
  }
}
.coinruleBut_iconCheck {
  @include icon;

  &::after {
    opacity: 0.42;
    width: 18px;
    height: 18px;
    right: 14px;
    background-size: 18px;
    background-image: url(../assets/images/icons/coinruleBut_iconCheck_after.svg);
  }
}
.coinruleBut_iconCheckWhite {
  @include icon;

  &::after {
    opacity: 0.42;
    width: 18px;
    height: 18px;
    right: 14px;
    background-size: 18px;
    background-image: url(../assets/images/icons/coinruleBut_iconCheckWhite_after.svg);
  }
}
.coinruleBut_iconLock {
  @include icon;

  &::after {
    width: 18px;
    height: 18px;
    background-size: auto 100%;
    opacity: 0.4;
    background-image: url(../assets/images/icons/coinruleBut_iconLock_after.svg);
  }
}
.coinruleBut_iconPlus {
  @include icon;

  &::after {
    opacity: 0.3;
    background-image: url(../assets/images/icons/coinruleBut_iconPlus_after.svg);
  }
}
.coinruleBut_iconSpinner,
.coinruleBut_iconLoading {
  @include icon;

  &::after {
    opacity: 0.42;
    width: 18px;
    height: 18px;
    right: 14px;
    background-size: 18px;
    background-image: url(../assets/images/icons/whole-page-spinner_spinner_black.png) !important;
    animation: iconSpinning;
    animation-timing-function: linear;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
  }

  @keyframes iconSpinning {
    0% {
      transform: translateY(-50%) rotate(0deg);
    }
    100% {
      transform: translateY(-50%) rotate(360deg);
    }
  }
}
.coinruleBut_iconCaretRight {
  @include icon;

  &::after {
    opacity: 0.4;
    background-size: auto 100%;
    background-image: url(../assets/images/icons/coinruleBut_iconCaretRight_after.svg);
  }
}

.coinruleBut_iconCaretRight2 {
  @include icon;

  &::after {
    opacity: 0.5;
    width: 20px;
    height: 20px;
    background-size: auto 100%;
    background-image: url(../assets/images/icons/coinruleBut_iconCaretRight2_after.svg);
  }
}

.coinruleBut_iconExit {
  @include icon;
}
.coinruleBut_iconHelpCenter {
  @include icon;

  &::after {
    opacity: 0.4;
    width: 18px;
    height: 18px;
    background-size: 100%;
    background-image: url(../assets/images/icons/coinruleBut_iconHelpCenter_after.svg);
  }
}
.coinruleBut_iconLogout {
  @include icon;

  &::after {
    opacity: 0.4;
    width: 20px;
    height: 20px;
    right: 12px;
    background-size: 100%;
    background-image: url(../assets/images/icons/coinruleBut_iconLogout_after.svg);
  }
}
.coinruleBut_iconLogoutWhite {
  @include icon;

  &::after {
    opacity: 0.15;
    width: 20px;
    height: 20px;
    right: 12px;
    background-size: 100%;
    background-image: url(../assets/images/icons/coinruleBut_iconLogoutWhite_after.svg);
  }
}

.coinruleBut_iconList {
  @include icon;

  &::after {
    background-size: 20px;
    background-image: url(../assets/images/icons/coinruleBut_iconList_after.svg);
    opacity: 0.3;
  }
}

.coinruleBut_iconDemo {
  @include icon;

  &::after {
    width: 23px;
    height: 23px;
    background-size: 23px;
    background-image: url(../assets/images/icons/coinruleBut_iconDemo.svg);
  }
}

.coinruleBut_iconEdit {
  @include icon;

  &::after {
    background-size: auto 100%;
    background-image: url(../assets/images/icons/coinruleBut_iconEdit_after.svg);
    opacity: 0.3;
  }
}

.coinruleBut_withGlow {
  &::before {
    box-shadow: 0px 2px 13px 5px #ffacdc;
  }
}
