/*
Font Family: 'cr_roboto'
Font Weights: 200, 300, 400, 500, 600, 700
*/

/* Thin 200 */
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-thin-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-thin-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-thin-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-thin-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-thin-webfont.svg#cr_roboto') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-thinitalic-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-thinitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-thinitalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-thinitalic-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-thinitalic-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-thinitalic-webfont.svg#cr_roboto') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

/* Light 300 */
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-light-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-light-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-light-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-light-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-light-webfont.svg#cr_roboto') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-lightitalic-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-lightitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-lightitalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-lightitalic-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-lightitalic-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-lightitalic-webfont.svg#cr_roboto') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/* Regular 400 */
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-regular-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-regular-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-regular-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-regular-webfont.svg#cr_roboto') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-italic-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-italic-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-italic-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-italic-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-italic-webfont.svg#cr_roboto') format('svg');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* Medium 500 */
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-medium-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-medium-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-medium-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-medium-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-medium-webfont.svg#cr_roboto') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-mediumitalic-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-mediumitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-mediumitalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-mediumitalic-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-mediumitalic-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-mediumitalic-webfont.svg#cr_roboto') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

/* Bold 600 */
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-bold-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-bold-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-bold-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-bold-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-bold-webfont.svg#cr_roboto') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-bolditalic-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-bolditalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-bolditalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-bolditalic-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-bolditalic-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-bolditalic-webfont.svg#cr_roboto') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/* Black 700 */
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-black-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-black-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-black-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-black-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-black-webfont.svg#cr_roboto') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'cr_roboto';
  src: url('../assets/fonts/Roboto/roboto-blackitalic-webfont.eot');
  src: url('../assets/fonts/Roboto/roboto-blackitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto/roboto-blackitalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/Roboto/roboto-blackitalic-webfont.woff') format('woff'),
    url('../assets/fonts/Roboto/roboto-blackitalic-webfont.ttf') format('truetype'),
    url('../assets/fonts/Roboto/roboto-blackitalic-webfont.svg#cr_roboto') format('svg');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/* ============================================================================
/* ============================================================================
/* ============================================================================
/* Lato */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/subset-Lato-Hairline.eot');
  src: url('../assets/fonts/Lato/subset-Lato-Hairline.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Lato/subset-Lato-Hairline.woff2') format('woff2'),
    url('../assets/fonts/Lato/subset-Lato-Hairline.woff') format('woff'),
    url('../assets/fonts/Lato/subset-Lato-Hairline.ttf') format('truetype'),
    url('../assets/fonts/Lato/subset-Lato-Hairline.svg#Lato-Hairline') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/subset-Lato-Light.eot');
  src: url('../assets/fonts/Lato/subset-Lato-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Lato/subset-Lato-Light.woff2') format('woff2'),
    url('../assets/fonts/Lato/subset-Lato-Light.woff') format('woff'),
    url('../assets/fonts/Lato/subset-Lato-Light.ttf') format('truetype'),
    url('../assets/fonts/Lato/subset-Lato-Light.svg#Lato-Light') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/subset-Lato-Regular.eot');
  src: url('../assets/fonts/Lato/subset-Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Lato/subset-Lato-Regular.woff2') format('woff2'),
    url('../assets/fonts/Lato/subset-Lato-Regular.woff') format('woff'),
    url('../assets/fonts/Lato/subset-Lato-Regular.ttf') format('truetype'),
    url('../assets/fonts/Lato/subset-Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/subset-Lato-Bold.eot');
  src: url('../assets/fonts/Lato/subset-Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Lato/subset-Lato-Bold.woff2') format('woff2'),
    url('../assets/fonts/Lato/subset-Lato-Bold.woff') format('woff'),
    url('../assets/fonts/Lato/subset-Lato-Bold.ttf') format('truetype'),
    url('../assets/fonts/Lato/subset-Lato-Bold.svg#Lato-Bold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/subset-Lato-Black.eot');
  src: url('../assets/fonts/Lato/subset-Lato-Black.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Lato/subset-Lato-Black.woff2') format('woff2'),
    url('../assets/fonts/Lato/subset-Lato-Black.woff') format('woff'),
    url('../assets/fonts/Lato/subset-Lato-Black.ttf') format('truetype'),
    url('../assets/fonts/Lato/subset-Lato-Black.svg#Lato-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
