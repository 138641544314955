body {
  transition: padding 0.2s ease;
}

.body_promoBarBotPadding {
  padding-bottom: 58px;
}

/* ========================================================================== */
/* ========================================================================== */
/* ========================================================================== */
/* Promo bars */
.floatingPromoBar {
  height: 58px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 11;
  font: 600 22px/26px 'Roboto', sans-serif;
  color: #3128b4;
  background: #f7f8fa;
  opacity: 1;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.12);
  transition: opacity 0.2s ease;
}

.floatingPromoBar_invisible {
  opacity: 0;
  pointer-events: none;
}

.floatingPromoBar__closeBut {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -24px;
  left: 0;
  border-radius: 0 7px 0 0;
  background: #fff;
  cursor: pointer;
}

.floatingPromoBar__closeBut svg {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 7px;
  left: 7px;
  transition: transform 0.15s ease;
  opacity: 0.55;
}

.floatingPromoBar__closeBut:hover svg {
  transform: scale(1.28);
}

.floatingPromoBar__content {
  height: 100%;
  padding-left: 106px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.floatingPromoBar__content_biggerLeftPadding {
  padding-left: 252px;
}

.floatingPromoBar__image {
  width: 86px;
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 11px;
  pointer-events: none;
}

.floatingPromoBar__image img {
  width: 100%;
}

.floatingPromoBar__image_teamPhoto {
  width: 232px;
  height: 79px;
}
.floatingPromoBar__textNbutWrap {
  display: flex;
  align-items: center;
  align-content: center;
}

.floatingPromoBar__title {
}

.floatingPromoBar__button {
  height: 38px;
  padding: 0 20px 3px;
  margin-left: 20px;
  box-sizing: border-box;
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font: 400 17px/23px 'Lato', sans-serif;
  text-decoration: none;
  color: #000;
  background: #ffd835;
  cursor: pointer;
  transition: opacity 0.15s ease;
}

.floatingPromoBar__button:hover {
  opacity: 0.65;
  color: #fff;
}

.floatingPromoBar__button_crimson {
  color: #fff;
  background: #ff165e;
}

.floatingPromoBar__button_boldFont {
  font-weight: 600;
}

/* *** MEDIA QUERIES *** */
@media (max-width: 979px) {
  .floatingPromoBar {
    height: 86px;
  }

  .floatingPromoBar__closeBut {
    width: 32px;
    height: 32px;
    top: -32px;
  }

  .floatingPromoBar__closeBut svg {
    width: 16px;
    height: 16px;
    top: 8px;
    left: 8px;
  }

  .floatingPromoBar__closeBut:hover svg {
    transform: none;
  }

  .floatingPromoBar__content_biggerLeftPadding {
    padding-left: 300px;
  }

  .floatingPromoBar__image {
    width: 115px;
    height: 107px;
  }

  .floatingPromoBar__image_teamPhoto {
    width: 306px;
    height: 105px;
  }

  .floatingPromoBar__textNbutWrap {
    padding-left: 18px;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    position: relative;
  }

  .floatingPromoBar__title {
    font-size: 18px;
    line-height: 22px;
  }

  .floatingPromoBar__button {
    height: 32px;
    margin-top: 5px;
    margin-left: -5px;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .floatingPromoBar {
    height: 95px;
  }

  .floatingPromoBar_teamPhoto {
    height: 83px;
  }

  .floatingPromoBar__content_biggerLeftPadding {
    padding-left: 0;
  }

  .floatingPromoBar__image {
    width: 122px;
    height: 113px;
  }

  .floatingPromoBar__image_teamPhoto {
    display: none;
  }

  .floatingPromoBar__textNbutWrap {
    padding-left: 0;
    margin-left: -7px;
  }

  .floatingPromoBar__content_biggerLeftPadding .floatingPromoBar__textNbutWrap {
    margin-left: 0;
  }

  .floatingPromoBar__image_teamPhoto + .floatingPromoBar__textNbutWrap {
    align-items: center;
    align-content: center;
  }

  .floatingPromoBar__title {
    padding-right: 35px;
    font-size: 15px;
    line-height: 17px;
    font-weight: 800;
  }

  .floatingPromoBar__image_teamPhoto + .floatingPromoBar__textNbutWrap .floatingPromoBar__title {
    padding-right: 0;
  }

  .floatingPromoBar__button {
    font-weight: 600;
  }

  .floatingPromoBar__image_teamPhoto + .floatingPromoBar__textNbutWrap .floatingPromoBar__button {
    margin-left: 0;
  }
}
