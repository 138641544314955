.lazyLoadContent {
  width: 100%;
  padding: 0;
  align-items: center;
  display: flex;
  flex: 100%;

  .lazyLoadInner {
    &.icon-loader {
      position: static;
    }
  }
}
