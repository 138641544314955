.custom-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 3px;

  &s {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}